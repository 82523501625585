import { default as SidebarContent } from "@/v2-ui/sidebar/SidebarContent"
import { default as SidebarSeparator } from "@/v2-ui/sidebar/SidebarSeparator"

import SidebarProvider from "./sidebar.context"
import Sidebar from "./Sidebar"
import SidebarItem from "./SidebarItem"
import SidebarBranding from "./SidebarBranding"
import SidebarFooter from "./SidebarFooter"
import SidebarGroup from "./SidebarGroup"
import SidebarCollapsibleItem from "./SidebarCollapsibleItem"
import SidebarCollapsibleSubItem from "./SidebarCollapsibleSubItem"
import SidebarToggle from "./SidebarToggle"

export type { SidebarProps } from "./Sidebar"
export type { SidebarItemProps } from "./SidebarItem"
export type { SidebarGroupProps } from "./SidebarGroup"
export type { SidebarCollapsibleItemProps } from "./SidebarCollapsibleItem"
export type { SidebarCollapsibleSubItemProps } from "./SidebarCollapsibleSubItem"
export type { SidebarToggleProps } from "./SidebarToggle"

export { useSidebarContext } from "./sidebar.context"

export default Object.assign(Sidebar, {
  Provider: SidebarProvider,
  Item: SidebarItem,
  Branding: SidebarBranding,
  Content: SidebarContent,
  Separator: SidebarSeparator,
  Group: SidebarGroup,
  Footer: SidebarFooter,
  CollapsibleItem: SidebarCollapsibleItem,
  CollapsibleSubItem: SidebarCollapsibleSubItem,
  Toggle: SidebarToggle
})
