import { cva } from "class-variance-authority"

/**
 * Button core variants configuration.
 *
 * This configuration defines various button styles and sizes.
 *
 * Variants:
 * - `variant`:
 *   - `default`: Primary button style with background and text color.
 *   - `destructive`: Destructive button style with background and text color.
 *   - `outline`: Button with border and background color.
 *   - `secondary`: Secondary button style with background and text color.
 *   - `ghost`: Button with hover background and text color.
 *   - `link`: Button styled as a link with underline.
 *   - `rounded`: Button with rounded-full style.
 * - `size`:
 *   - `default`: Default size with height, padding, and text size.
 *   - `sm`: Small size with height, padding, and text size.
 *   - `lg`: Large size with height, padding, and text size.
 *   - `icon`: Icon size with equal height and width.
 *
 * Default Variants:
 * - `variant`: `default`
 * - `size`: `default`
 */
export const buttonCoreVariants = cva(
  "",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline: "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        rounded: "rounded-full",
        "no-background": "hover:text-accent-foreground"
      },
      size: {
        fit: "h-fit rounded-md text-xs",
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-12 rounded-md px-8",
        icon: "h-9 w-9",
        tiny: "h-6 w-6"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
)
