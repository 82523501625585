import type { ComponentProps } from "react"
import { useMemo } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Avatar from "@/v2-ui/avatar"
import Skeleton from "@/v2-ui/skeleton"
import { getRandomColorBySeed }
  from "@/v2-console-shared/utils/utils.color"
import { useRootText } from "@/v2-console-shared/texts"

export type CurrentUserAvatarProps = ComponentProps<"div"> & {
  name: string,
  subName: string,
  isLoading?: boolean
  avatarSize?: number
}

function CurrentUserAvatar(props: CurrentUserAvatarProps) {
  const {
    name,
    subName,
    isLoading,
    className,
    avatarSize = 9,
    ...divProps
  } = props

  const { getText } = useRootText()

  const avatarStyle = useMemo(() => {
    if(!name) return null
    return {
      backgroundColor: getRandomColorBySeed(name)
    }
  }, [
    name
  ])

  return (
    <div
      className={cn("flex gap-2 w-full items-center", className)}
      {...divProps}
    >
      <Avatar
        className={`w-${avatarSize} h-${avatarSize}`}
        isLoading={isLoading}
        style={avatarStyle}
        aria-label={getText("UI_CurrentUserAvatar_Root").Name}
      >
        <Avatar.Fallback>
          {name[0]}
        </Avatar.Fallback>
      </Avatar>

      <div className="h-auto flex flex-col w-full">
        {
          isLoading ? (
            <>
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-2/3 h-2 mt-1" />
            </>
          ) : (
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate">
                {name}
              </span>
              <span className="opacity-50 truncate text-xs">
                {subName}
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CurrentUserAvatar
