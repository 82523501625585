import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import StateDisplay from "@/v2-layout-ui/state"
import { STATE_LOADING_TYPES }
  from "@/v2-layout-ui/state/loading/state.loading.constants"

const baseMsg = "Venter på autentisering"

function getLoadingMessage(msg: InteractionStatus) {
  if(!msg) return `${baseMsg}..`
  return `${baseMsg} (${msg})..`
}

type AuthLoaderProps = {
  children: any
}

function AuthLoader(props: AuthLoaderProps) {
  const { inProgress } = useMsal()

  if(!inProgress || inProgress !== InteractionStatus.None) {
    return (
      <StateDisplay
        stateType={STATE_LOADING_TYPES.LOADING}
        title={getLoadingMessage(inProgress)}
      />
    )
  }

  return props.children
}

export default AuthLoader
