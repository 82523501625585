import { useText } from "@/v2-ui/text/text.context"
import type { RootTexts } from "@/v2-console-shared/texts"

const LANGUAGE_SELECTOR_TEXT = {
  "UI_LanguageSelector_Header": {
    "Name": "Language",
    "Description": "Select the language in which to display all texts across the application"
  },
  "UI_LanguageSelector_SearchPlaceholder": {
    "Name": "Search for a language"
  },
  "UI_LanguageSelector_SearchEmpty": {
    "Name": "No language found..."
  }
} as const

export type LangaugeSelectorTexts = typeof LANGUAGE_SELECTOR_TEXT & RootTexts
export const useLanguageSelectorText = useText<LangaugeSelectorTexts>
export default LANGUAGE_SELECTOR_TEXT
