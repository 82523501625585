import { forwardRef } from "react"
import type { ReactNode } from "react"
import Tooltip from "@/v2-ui/tooltip"
import cn from "@/v2-ui/utils/utils.cn"
import Button, { type ButtonProps } from "./Button"
import type { TooltipContentProps, TooltipProps, TooltipTriggerProps }
  from "@/v2-ui/tooltip"

type BaseProps = Omit<ButtonProps, "label" | "content">
export type TooltipButtonProps = BaseProps & {
  label?: string,
  content?: ReactNode,
  tooltipContentSide?: TooltipContentProps["side"],
  contentClassName?: string,
  open?: TooltipProps["open"],
  tooltipOnClose?: () => void,
  tooltipTriggerStyle?: TooltipTriggerProps["style"],
  tooltipOnMouseEnter?: TooltipTriggerProps["onMouseEnter"],
  tooltipOnMouseLeave?: TooltipTriggerProps["onMouseLeave"],
  tooltipOnMouseDown?: TooltipTriggerProps["onMouseDown"],
  tooltipOnMouseUp?: TooltipTriggerProps["onMouseUp"],
  tooltipTriggerClassName?: TooltipTriggerProps["className"],
  tooltipDelayDuration?: TooltipProps["delayDuration"]
}

function TooltipButton(props: TooltipButtonProps, ref) {
  const {
    label,
    content,
    tooltipContentSide = "top",
    contentClassName,
    open,
    tooltipOnClose,
    tooltipTriggerStyle,
    tooltipOnMouseEnter,
    tooltipOnMouseLeave,
    tooltipOnMouseDown,
    tooltipOnMouseUp,
    tooltipTriggerClassName,
    tooltipDelayDuration,
    ...buttonProps
  } = props

  return (
    <Tooltip
      open={open}
      onOpenChange={(open) => {
        if(!open) {
          tooltipOnClose?.()
        }
      }}
      delayDuration={tooltipDelayDuration}
    >
      <Tooltip.Trigger
        tabIndex={-1}
        style={tooltipTriggerStyle}
        className={cn("tooltip-button__popover", tooltipTriggerClassName)}
        onMouseEnter={tooltipOnMouseEnter}
        onMouseLeave={tooltipOnMouseLeave}
        onMouseDown={tooltipOnMouseDown}
        onMouseUp={tooltipOnMouseUp}
        ref={ref}
      >
        <Button
          aria-label={label}
          {...buttonProps}
        />
      </Tooltip.Trigger>
      <Tooltip.Content
        side={tooltipContentSide}
        className={contentClassName}
      >
        {content || label}
      </Tooltip.Content>
    </Tooltip>
  )
}

export default forwardRef(TooltipButton)
