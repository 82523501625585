import { Outlet } from "react-router-dom"
import ApolloClientProvider from "@/v2-console/apollo/ApolloClientProvider"
import AppContextLoader from "@/v2-console/app/context/AppContextLoader"
import AppSidebar from "@/v2-console/app/sidebar/AppSidebar"
import AppReadyIndicator from "@/v2-console/app/AppReadyIndicator"
import TextProvider from "@/v2-console/text/TextProvider"
import { ROOT_TEXT, type RootTexts }
  from "@/v2-console-shared/texts"
import Sidebar from "@/v2-layout-ui/sidebar"

function AppOutlet() {
  return (
    <ApolloClientProvider>
      <AppContextLoader>
        <TextProvider<RootTexts>
          isRoot
          fallbackTexts={ROOT_TEXT}
        >
          <Sidebar.Provider>
            <AppSidebar />
            <Outlet />
            <AppReadyIndicator />
          </Sidebar.Provider>
        </TextProvider>
      </AppContextLoader>
    </ApolloClientProvider>
  )
}

export default AppOutlet
