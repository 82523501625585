import { useMemo } from "react"
import type { CurrentUserCountryFragment }
  from "@/v2-console-shared/currentUser/country/__types__/currentUser.country.fragment"
import { getFlagAssetUrl } from "@/v2-ui/utils/utils.assets"
import CommandSimple, { type CommandSimpleItemProps }
  from "@/v2-console-ui/commandSimple"
import { useCountrySelectorText }
  from "@/v2-console-ui/countrySelector/countrySelector.text"

export type CountrySelectorProps = {
  activeCountry?: CurrentUserCountryFragment,
  countries?: CurrentUserCountryFragment[],
  onCountryChange: (countryCode: number) => void,
  loading?: boolean,
  error?: Error,
}

const CountrySelector = (props: CountrySelectorProps) => {
  const {
    activeCountry,
    countries = [],
    onCountryChange,
    loading,
    error
  } = props

  const { getText } = useCountrySelectorText()

  const selectMenuData: CommandSimpleItemProps[] = useMemo(() =>
    countries.map((country) => ({
      value: country.Code,
      label: country.Name,
      icon: <img
        src={getFlagAssetUrl(country.Alpha2.toLowerCase())}
        alt=""
        className="object-cover h-4 w-6"
      />
    })), [ countries ])

  return (
    <CommandSimple
      items={selectMenuData}
      activeValue={activeCountry?.Code}
      loading={loading}
      heading={getText("UI_CountrySelector_Header").Name}
      aria-label={getText("UI_CountrySelector_Header").Name}
      searchPlaceholder={getText("UI_CountrySelector_SearchPlaceholder").Name}
      emptyMessage={getText("UI_CountrySelector_SearchEmpty").Name}
      error={error}
      onSelectChange={(value) => {
        const country = countries.find((c) => c.Code === value)
        if(country) {
          onCountryChange(country.Code)
        }
      }}
    />
  )
}

export default CountrySelector
