import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CURRENT_USER_TEXT = {
  "UI_CurrentUser_Header": {
    "Name": "Profile"
  },
  "UI_CurrentUser_Logout": {
    "Name": "Log out"
  },

  // Tenant picker
  "UI_CurrentUser_TenantPicker": {
    "Name": "Change tenant"
  },
  "UI_CurrentUser_TenantPickerItem": {
    "Name": "Set item as active tenant"
  },

  // Profile
  "UI_CurrentUser_ProfileFormHeader": {
    "Name": "Profile",
    "Description": "Edit your profile"
  },

  // Profile names
  "UI_CurrentUser_ProfileFirstName": {
    "Name": "First name"
  },
  "UI_CurrentUser_ProfileLastName": {
    "Name": "Last name"
  },
  "UI_CurrentUser_ProfileEmail": {
    "Name": "Email"
  },

  // Profile placeholders
  "UI_CurrentUser_ProfileFirstNamePlaceholder": {
    "Name": "Write your first name.."
  },
  "UI_CurrentUser_ProfileLastNamePlaceholder": {
    "Name": "Write your last name.."
  },
  "UI_CurrentUser_ProfileEmailPlaceholder": {
    "Name": "Write your email.."
  },

  // Profile validation errors
  "UI_CurrentUser_ProfileFirstNameEmptyError": {
    "Name": "Please enter your first name.."
  },
  "UI_CurrentUser_ProfileFirstNameTooLongError": {
    "Name": "First name cannot exceed 64 characters"
  },

  "UI_CurrentUser_ProfileLastNameEmptyError": {
    "Name": "Please enter your last name.."
  },
  "UI_CurrentUser_ProfileLastNameTooLongError": {
    "Name": "Last name cannot exceed 255 characters"
  },

  "UI_CurrentUser_ProfileEmailEmptyError": {
    "Name": "Please enter your email.."
  },
  "UI_CurrentUser_ProfileEmailTooLongError": {
    "Name": "Email cannot exceed 255 characters"
  },
  "UI_CurrentUser_ProfileEmailInvalidError": {
    "Name": "Please enter a valid email.."
  },

  // Profile buttons
  "UI_CurrentUser_ProfileFormCancel": {
    "Name": "Cancel"
  },
  "UI_CurrentUser_ProfileFormSubmit": {
    "Name": "Save"
  },

  // Profile submit errors
  "UI_CurrentUser_ProfileFormOnSuccess": {
    "Name": "Successfully saved",
    "Description": "The profile has been successfully saved"
  },
  "UI_CurrentUser_ProfileFormOnError": {
    "Name": "Failed to save profile",
    "Description": "Failed to save the profile with the following error:"
  }

} as const

export type CurrentUserTexts = typeof CURRENT_USER_TEXT & RootTexts
export const useCurrentUserText = useText<CurrentUserTexts>
export default CURRENT_USER_TEXT
