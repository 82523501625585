import { type ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import { useThemeCustomizerText }
  from "@/v2-console-ui/themeCustomizer/themeCustomizer.text"

type ThemeCustomizerFontSizePreviewProps = ComponentProps<"div"> & {
  fontSize: number
}
function ThemeCustomizerFontSizePreview(props: ThemeCustomizerFontSizePreviewProps) {
  const {
    fontSize,
    className,
    ...divProps
  } = props
  const { getText } = useThemeCustomizerText()

  return (
    <div
      className={cn("flex flex-col justify-center p-4 bg-muted rounded-md h-[80px]", className)}
      style={{ fontSize: `${fontSize}px` }}
      {...divProps}
    >
      <div
        className="font-bold"
        aria-label={getText("UI_Theme_FontSizePreviewTextLarge").Name}
      >
        {getText("UI_Theme_FontSizePreviewTextLarge").Name}
      </div>
      <div
        className="text-muted-foreground"
        style={{ fontSize: `${fontSize * 0.875}px` }}
        aria-label={getText("UI_Theme_FontSizePreviewTextSmall").Name
        }
      >
        {getText("UI_Theme_FontSizePreviewTextSmall").Name}
      </div>
    </div>
  )
}

export default ThemeCustomizerFontSizePreview
