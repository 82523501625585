import type { ReactNode } from "react"
import { Suspense, useMemo } from "react"
import type { TextsBase } from "@/v2-ui/text/text.context"
import TextProviderBase from "@/v2-ui/text/text.context"
import { useAppContextQueryIsLoading }
  from "@/v2-console/app/context/app.context.query"
import useTextQuery from "@/v2-console/text/text.query"
import { mapTexts } from "@/v2-console/text/text.utils"
import { FEATURE_CODE_ROOT, FEATURE_CODE_NON_ROOT_POSTFIX }
  from "@/v2-console/text/text.constants"
// import TextErrorNotification from "@/v2-console/text/TextErrorNotification"
import FeatureLoadingFallback from "@/v2-console/feature/FeatureLoadingFallback"

const DEFAULT_FALLBACK_TEXTS = {}
const DEFAULT_INVALID_CODE = "invalid-code"

export type TextProviderProps<T extends TextsBase> = {
  isRoot?: boolean
  code?: string,
  fallbackTexts?: T
  isReady?: boolean
  loadingFallback?: ReactNode
  skipRenderBlock?: boolean
  children: ReactNode
}

/**
 * A provider component that supplies text data to its children, including handling
 * loading states, errors, and fallback text values.
 *
 * @param props - The properties for the `TextProvider` component.
 * @param props.code - The feature code to fetch the texts by.
 * @param props.fallbackTexts - The fallback texts used if no data is available.
 * @param props.isReady - Indicates whether the provider is ready to display the texts.
 * @param props.loadingFallback - Fallback UI to display while loading texts.
 * @param props.skipRenderBlock - Allows consumer to not renderblock children on loading and let them control loading themself
 * @param props.children - The child components that will consume the text context.
 *
 * @returns A `TextProviderBase` component that supplies text values and state to its children.
 */
function TextProvider<T extends TextsBase>(
  props: TextProviderProps<T>
) {
  const {
    isRoot = false,
    code = DEFAULT_INVALID_CODE, // @note: needed to prevent fetch-all by default
    fallbackTexts = DEFAULT_FALLBACK_TEXTS as T,
    isReady = true,
    loadingFallback = <FeatureLoadingFallback />,
    skipRenderBlock,
    children
  } = props

  const isAppLoading = useAppContextQueryIsLoading()

  const {
    data,
    loading: isTextQueryLoading,
    error
  } = useTextQuery({
    variables: {
      input: {
        code: isRoot
          ? FEATURE_CODE_ROOT
          : `${code}${FEATURE_CODE_NON_ROOT_POSTFIX}`
      }
    }
    // @note: this error could fire on resetStore if inflight when resetStore
    // is called. Therefore we do NOT add it, as the error provides
    // no real value in general
    // fallback texts
    // onError(e) {
    //   const t = toast({
    //     variant: "destructive",
    //     title: "An error occurred while fetching the language",
    //     description: (
    //       <TextErrorNotification
    //         error={e}
    //         onRetry={() => {
    //           refetch()
    //           t.dismiss()
    //         }}
    //       />
    //     )
    //   })
    // }
  })

  const loading = !isReady || isTextQueryLoading || isAppLoading
  // @note: we do not renderBlock root text
  const shouldSkipRenderBlock = skipRenderBlock || isRoot

  const contextValue = useMemo(() => {
    const texts = {
      ...fallbackTexts,
      ...mapTexts(data?.getText)
    }
    return {
      texts,
      loading: loading,
      error: error
    }
  }, [
    fallbackTexts,
    data?.getText,
    loading,
    error
  ])

  return (
    <TextProviderBase<T> value={contextValue}>
      {loading && !shouldSkipRenderBlock
        ? loadingFallback
        : children
      }
    </TextProviderBase>
  )
}

export type TextProviderWithSuspenseProps<T extends TextsBase> = TextProviderProps<T> & {
  isLazyLoaded?: boolean
}

/**
 * A wrapper around `TextProvider` that adds support for lazy loading with React's `Suspense`.
 *
 * @param props - The properties for the `TextProvider` component.
 * @param props.code - The feature code to fetch the texts by.
 * @param props.fallbackTexts - The fallback texts used if no data is available.
 * @param props.isReady - Indicates whether the provider is ready to display the texts.
 * @param props.loadingFallback - Fallback UI to display while loading texts.
 * @param props.skipRenderBlock - Allows consumer to not renderblock children on loading and let them control loading themself
 * @param props.children - The child components that will consume the text context.
 *
 * @returns A component that provides texts, with optional support for lazy loading.
 */
function TextProviderWithSuspense<T extends TextsBase>(
  props: TextProviderWithSuspenseProps<T>
) {
  const {
    isLazyLoaded = false,
    loadingFallback = <FeatureLoadingFallback />,
    children,
    ...textProviderProps
  } = props

  if(isLazyLoaded) {
    return (
      <Suspense fallback={loadingFallback}>
        <TextProvider<T>
          loadingFallback={loadingFallback}
          {...textProviderProps}
        >
          {children}
        </TextProvider>
      </Suspense>
    )
  }

  return (
    <TextProvider<T>
      loadingFallback={loadingFallback}
      {...textProviderProps}
    >
      {children}
    </TextProvider>
  )
}

export default TextProviderWithSuspense
