import { ErrorBoundary } from "@sentry/react"
import type { FallbackRender } from "@sentry/react"
import type { TextsBase } from "@/v2-ui/text/text.context"
import { StateErrorFallbackRender } from "@/v2-layout-ui/state"
import TextProviderWithSuspense, { type TextProviderWithSuspenseProps }
  from "@/v2-console/text/TextProvider"

export type FeatureProviderProps<T extends TextsBase> = TextProviderWithSuspenseProps<T> & {
  errorFallback: FallbackRender
}
function FeatureProvider<T extends TextsBase>({
  errorFallback = StateErrorFallbackRender,
  children,
  ...props
}) {

  return (
    <ErrorBoundary fallback={errorFallback}>
      <TextProviderWithSuspense<T>
        {...props}
      >
        {children}
      </TextProviderWithSuspense>
    </ErrorBoundary>
  )
}

export default FeatureProvider
