import { STATE_DATA_TYPES } from "@/v2-layout-ui/state/data/state.data.constants"
import type { StateDataType } from "@/v2-layout-ui/state/data/state.data.types"
import StateDataNoData from "@/v2-layout-ui/state/data/StateDataNoData"

export type StateDataProps = {
  stateDataType: StateDataType
}

function StateData(props: StateDataProps) {
  const { stateDataType } = props

  if(stateDataType === STATE_DATA_TYPES.NO_DATA) {
    return (
      <StateDataNoData />
    )
  }
  return null
}

export default StateData
