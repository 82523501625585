/**
 * @note: This is only used int he text.root.text, and is splitted
 * out for better readability and maintainability.
 */
export const ROOT_COMMON_TEXT = {
  "UI_Common_OnlyIn3DBadge_Root": {
    "Name": "Only in 3D",
    "Description": "This feature is only available in 3D view."
  },
  "UI_Common_2D_Root": {
    "Name": "2D",
    "Description": "Activate 2D view."
  },
  "UI_Common_3D_Root": {
    "Name": "3D",
    "Description": "Activate 3D view."
  },
  "UI_Common_Input_Name_Root": {
    "Name": "Name Input",
    "Description": "Type a name...",
    "__typename": "Feature"
  },
  "UI_Common_Save_Root": {
    "Name": "Save",
    "Description": "Save the current data."
  }
} as const

export type RootCommonTexts = typeof ROOT_COMMON_TEXT
