import { useText } from "@/v2-ui/text/text.context"
import type { RootTexts } from "@/v2-console-shared/texts"

const THEME_CUSTOMIZER_TEXT = {
  // theme customizer
  "UI_Theme_Header": {
    "Name": "Theme",
    "Description": "Customize the color theme of the application"
  },
  // mode
  "UI_Theme_ModeSelector": {
    "Name": "Mode",
    "Description": "Select the light/dark mode of the application"
  },
  // theme
  "UI_Theme_ThemeSelector": {
    "Name": "Theme",
    "Description": "Select a theme for the application"
  },
  // font size customizer
  "UI_Theme_FontSizeSelector": {
    "Name": "Font Size",
    "Description": "Select the font size of the application"
  },
  "UI_Theme_FontSizePreviewTextLarge": {
    "Name": "Preview text with new font size"
  },
  "UI_Theme_FontSizePreviewTextSmall": {
    "Name": "Preview text with new font size"
  },
  "UI_Theme_FontSizeApply": {
    "Name": "Apply"
  }

} as const

export type ThemeCustomizerTexts = typeof THEME_CUSTOMIZER_TEXT & RootTexts
export const useThemeCustomizerText = useText<ThemeCustomizerTexts>
export default THEME_CUSTOMIZER_TEXT
