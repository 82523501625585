import { type ReactNode, useCallback } from "react"
import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import THEME_CUSTOMIZER_TEXT, { type ThemeCustomizerTexts }
  from "@/v2-console-ui/themeCustomizer/themeCustomizer.text"
import ThemeCustomizer from "@/v2-console-ui/themeCustomizer/ThemeCustomizer"
import { useRootText } from "@/v2-console-shared/texts"
import { trackAppThemeConfigChangedByUser }
  from "@/v2-console/app/app.tracking"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"

export type ThemeCustomizerProps = {
  children?: ReactNode
}

export type AppSidebarItemThemeProps = Partial<SidebarItemProps>

function AppSidebarItemTheme(props: AppSidebarItemThemeProps) {
  const { ...sidebarItemProps } = props
  const { getText } = useRootText()

  const onThemeChanged = useCallback((field: string, value: string | number) => {
    trackAppThemeConfigChangedByUser(field, value)
  }, [])

  return (
    <Sidebar.Item
      iconSrc="paint-brush"
      label={getText("UI_Theme_Root").Name}
      tooltip={getText("UI_Theme_Root").Name}
      popoverContent={() => (
        <FeatureProvider<ThemeCustomizerTexts>
          code="UI_Theme"
          fallbackTexts={THEME_CUSTOMIZER_TEXT}
          isLazyLoaded={false} // @note: Cannot lazy load this component without creating new loader component
        >
          <ThemeCustomizer
            onThemeChange={onThemeChanged}
          />
        </FeatureProvider>
      )}
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemTheme
