import { ROOT_COMMON_TEXT } from "./texts.rootCommon.text"
import { useText } from "@/v2-ui/text/text.context"

const ROOT_TEXT = {
  // theme customizer
  "UI_Theme_Root": {
    "Name": "Theme",
    "Description": "Select a theme for the application."
  },

  // theme toggle
  "UI_Theme_Light_Root": {
    "Name": "Light mode",
    "Description": "Enable light theme for increased brightnes."
  },
  "UI_Theme_Dark_Root": {
    "Name": "Dark mode",
    "Description": "Enable dark theme for reduced brightness."
  },

  // country selector
  "UI_CountrySelector_Root": {
    "Name": "Country",
    "Description": "Select country."
  },
  // language selector
  "UI_LanguageSelector_Root": {
    "Name": "Language",
    "Description": "Select language."
  },

  // basemap
  "UI_BasemapSelector_Root": {
    "Name": "Background Map",
    "Description": "The map shown in the background of the mapview."
  },

  // widgets
  "UI_Print_Root": {
    "Name": "Print",
    "Description": "Export the map as a PDF or image."
  },
  //UI_Widget_ShadowCast_Root
  "UI_ShadowCast_Root": {
    "Name": "Shadow",
    "Description": "Visualize shadows on the map during a given time period."
  },
  "UI_Measure_Root": {
    "Name": "Measurments",
    "Description": "Measure distances and areas on the map."
  },
  "UI_LineOfSight_Root": {
    "Name": "Line of Sight",
    "Description": "Draw lines from a point-of-interest to visualize line-of-sight along the line."
  },
  // ""UI_Widget_ElevationProfile_Root",
  "UI_ElevationProfile_Root": {
    "Name": "Elevation Profile",
    "Description": "Draw lines to generate elevation profiles."
  },
  //UI_Widget_Daylight_Root
  "UI_Daylight_Root": {
    "Name": "Daylight",
    "Description": "Adjust the slider to measure daylight at the selected location."
  },
  "UI_Bookmark_Root": {
    "Name": "Bookmarks",
    "Description": "Manage stored locations and viewpoints on the map."
  },
  "UI_Settings3D_Root": {
    "Name": "3D Settings",
    "Description": "Change settings for maps and buildings in 3D view."
  },

  // left sidebar
  "UI_SidebarMain_Toggle_Root": {
    "Name": "Toggle sidebar"
  },
  "UI_SidebarWidget_Toggle_Root": {
    "Name": "Toggle sidebar"
  },
  "UI_Layers_Root": {
    "Name": "Map Layers",
    "Description": "Select and manage one or more map layers in the view."
  },
  "UI_Help_Root": {
    "Name": "Help",
    "Description": "Show help texts."
  },

  // toolbar
  "UI_Compass_Root": {
    "Name": "Compass",
    "Description": "Reset the map orientation to north."
  },
  "UI_FocusMode_Root": {
    "Name": "Focus Mode",
    "Description": "Hide the user interface."
  },
  "UI_Fullscreen_Enable_Root": {
    "Name": "Enable Fullscreen",
    "Description": "Enable fullscreen mode for better viewing."
  },
  "UI_Screenshot_Root": {
    "Name": "Screenshot",
    "Description": "Export the current map as an image."
  },
  "UI_Settings_Root": {
    "Name": "Settings",
    "Description": "Open settings for customization."
  },
  "UI_ZoomIn_Root": {
    "Name": "Zoom In",
    "Description": "Move the camera closer to the map."
  },
  "UI_ZoomOut_Root": {
    "Name": "Zoom Out",
    "Description": "Move the camera away from the map."
  },

  // navlayer
  "NavLayer_NO_Buildings_Root": {
    "Name": "Building Boundaries (Norway)",
    "Description": "Displays building boundaries in Norway. Hover over the map to see buildings and select a building for details."
  },
  "NavLayer_NO_Cadastre_Root": {
    "Name": "Cadastre Boundaries (Norway)",
    "Description": "Displays property boundaries in Norway. Hover over the map to see boundaries and select a property for details."
  },
  // current user
    "UI_CurrentUser_Root": {
    "Name": "Select language",
    "Description": "Select the language in which to display all texts across the application"
  },
  "UI_CurrentUserAvatar_Root": {
    "Name": "User Avatar"
  },
  "UI_BuildingStyle_Root": {
    "Name": "Building Style",
    "Description": "Select a style for the buildings on the map."
  },
  ...ROOT_COMMON_TEXT
} as const

export type RootTexts = typeof ROOT_TEXT
export const useRootText = useText<RootTexts>
export default ROOT_TEXT
