import { useState, useEffect, useRef } from "react"
import { useIsAuthenticated } from "@azure/msal-react"
import { STATE_LOADING_TYPES }
  from "@/v2-layout-ui/state/loading/state.loading.constants"
import StateDisplay from "@/v2-layout-ui/state"
import AuthLoginPage from "@/v2-console/auth/AuthLoginPage"
import { acquireAndCheckTokenOrRedirectToLogin }
  from "@/v2-console/auth/auth.instance"

export type AuthGuardProps = {
  children: any
}

function AuthGuard(props: AuthGuardProps) {
  const isAuthenticated = useIsAuthenticated()
  const [ isFetchingToken, setIsFetchingToken ] = useState(true)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if(!isAuthenticated) return
    acquireAndCheckTokenOrRedirectToLogin()
      .then(() => {
        if(isMounted.current) {
          setIsFetchingToken(false)
        }
      })
  }, [
    isAuthenticated
  ])

  if(!isAuthenticated) {
    return <AuthLoginPage />
  }

  if(isFetchingToken) {
    return (
      <StateDisplay
        stateType={STATE_LOADING_TYPES.LOADING}
        title="Venter på autentisering (token).."
      />
    )
  }

  return props.children
}

export default AuthGuard
