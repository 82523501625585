import { Suspense } from "react"
import {
  unstable_HistoryRouter as HistoryRouter
} from "react-router-dom"
import StateDisplay from "@/v2-layout-ui/state"
import { STATE_LOADING_TYPES }
  from "@/v2-layout-ui/state/loading/state.loading.constants"
import AuthProvider from "@/v2-console/auth/AuthProvider"
import Routes from "@/v2-console/router/Routes"
import RouterInstrumenter from "@/v2-console/router/RouterInstrumenter"
import history from "@/v2-console/router/router.history"

function Router() {
  return (
    <HistoryRouter history={history as any}>
      <AuthProvider>
        <Suspense fallback={(
          <StateDisplay
            stateType={STATE_LOADING_TYPES.LOADING}
            title="Laster inn grensesnittet.."
          />
        )}>
          <Routes />
        </Suspense>

        <RouterInstrumenter />
      </AuthProvider>
    </HistoryRouter>
  )
}

export default Router
