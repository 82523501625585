import { useMemo } from "react"
import type { CurrentUserLanguageFragment }
  from "@/v2-console-shared/currentUser/language/__types__/currentUser.language.fragment"
import { getFlagAssetUrl } from "@/v2-ui/utils/utils.assets"
import CommandSimple, { type CommandSimpleItemProps }
  from "@/v2-console-ui/commandSimple"
import { useLanguageSelectorText }
  from "@/v2-console-ui/languageSelector/languageSelector.text"

export type LanguageSelectorProps = {
  activeLanguage?: CurrentUserLanguageFragment,
  languages?: CurrentUserLanguageFragment[],
  onLanguageChange: (lang: CurrentUserLanguageFragment) => void,
  loading?: boolean,
  error?: Error,
}

const LanguageSelector = (props: LanguageSelectorProps) => {
  const {
    activeLanguage,
    languages = [],
    onLanguageChange,
    loading,
    error
  } = props

  const { getText } = useLanguageSelectorText()

  const selectMenuData: CommandSimpleItemProps[] = useMemo(() =>
    languages.map((lang) => ({
      value: lang.Code,
      label: lang.Name,
      icon: <img
        src={getFlagAssetUrl(lang.Code?.split("-")[1].toLowerCase())}
        alt=""
        className="object-cover h-4 w-6"
      />
    })), [ languages ])

  return (
    <CommandSimple
      items={selectMenuData}
      activeValue={activeLanguage?.Code}
      loading={loading}
      heading={getText("UI_LanguageSelector_Header").Name}
      aria-label={getText("UI_LanguageSelector_Header").Name}
      searchPlaceholder={getText("UI_LanguageSelector_SearchPlaceholder").Name}
      emptyMessage={getText("UI_LanguageSelector_SearchEmpty").Name}
      error={error}
      onSelectChange={(value) => {
        const language = languages.find((c) => c.Code === value)
        if(language) {
          onLanguageChange(language)
        }
      }}
    />
  )
}

export default LanguageSelector
