import type { ReactNode } from "react"
import { createContext, useCallback, useContext, useMemo } from "react"
import {
  FALLBACK_TEXT,
  LOADING_TEXT
} from "@/v2-ui/text/text.constants"

// TextValue is fixed for all Text contexts
export type TextValue = {
  Name: string
  Description?: string
}

// The base type for all Texts
export type TextsBase = Record<string, TextValue>

// Generic text type that specifies the keys and their corresponding TextValue
export type Texts<T extends TextsBase> = T

export type TextProviderValue<T extends TextsBase> = {
  texts: Texts<Partial<T>> // @note: adding partial to allow for partial text objects
  loading?: boolean
  error?: Error | null
  getText?: (key: keyof T) => TextValue
}

const TextContext = createContext<TextProviderValue<any> | undefined>(undefined)

export type TextProviderProps<T extends TextsBase> = {
  value: TextProviderValue<T>
  children: ReactNode,
  useParentFallback?: boolean // Add a flag to enable global fallback
}

/**
 * Provides a context for accessing text values within the component tree.
 *
 * This component accepts a `value` prop, which is expected to contain a `texts` object
 * and optionally `loading` and `error` states. The `getText` function is defined using
 * `useCallback` to retrieve a specific text based on the provided key.
 * It performs the following steps:
 *
 * 1. Checks if the `loading` state is `true` and, if so, returns a `LOADING_TEXT`.
 * 2. Searches for the text in the local context's `texts` object.
 * 3. If not found locally and `useParentFallback` is enabled, attempts to retrieve
 *    the text from the parent context.
 * 4. Returns a `FALLBACK_TEXT` if no matching text is found.
 *
 * This hierarchical fallback mechanism enables reusability of text values across
 * nested contexts (whitout duplicating state) while allowing for local overrides.
 *
 * @typeParam T - A generic extending `TextsBase` representing the shape of the text data.
 * @param props - The `TextProviderProps` including:
 *   - `value`: The context value containing `texts`, `loading`, and `error`.
 *   - `children`: The child components wrapped by the provider.
 *   - `useParentFallback`: (Optional) Enables fallback to the parent context. Default is `true`.
 * @returns A `TextContext.Provider` wrapping the `children` components.
 */
function TextProvider<T extends TextsBase>(props: TextProviderProps<T>) {
  const { value, children, useParentFallback = true } = props
  // define the parent context in case we need to traverse
  const parentContext = useContext(TextContext)

  const getText = useCallback((key: keyof T): TextValue => {
    if(value.loading) return LOADING_TEXT

    // find thext in local context first
    const localText = value.texts?.[key]
    if(localText) return localText

    // then try to find in parent context
    if(useParentFallback && parentContext?.getText) {
      const globalText = parentContext.getText(key)
      if(globalText) return globalText
    }

    // if nothing else woks return fallback
    return FALLBACK_TEXT

  }, [ value, parentContext, useParentFallback ])

  const contextValue = useMemo(() => ({
    ...value,
    getText
  }), [ value, getText ])

  return (
    <TextContext.Provider value={contextValue}>
      {children}
    </TextContext.Provider>
  )
}

/**
 * Custom hook to access the `TextContext` with proper typing.
 *
 * This hook retrieves the context value for text management and ensures that
 * it is being used within a `TextProvider`. If the context is not available,
 * it throws an error to enforce the provider usage.
 *
 * @typeParam T - A generic extending `TextsBase` representing the shape of the text data.
 * @throws Will throw an error if the hook is used outside of a `TextProvider`.
 * @returns The `TextProviderValue` containing the context value and functions.
 */
export function useText<T extends TextsBase>() {
  const context = useContext(TextContext)
  if(!context) {
    throw new Error("useText must be used within a TextProvider")
  }
  return context as TextProviderValue<T>
}

export default TextProvider
