import { forwardRef } from "react"
import type { ButtonProps } from "./Button"
import type { IconProps } from "@/v2-ui/icon"
import Icon from "@/v2-ui/icon"
import Button from "./Button"

export type BaseButtonIconProps = {
  iconPosition?: "left" | "right", // Maybe before and after?
  iconSize?: IconProps["size"],
  iconSrc: IconProps["src"],
  iconBackdrop?: IconProps["iconBackdrop"],
  iconStyle?: IconProps["style"],
  isLoading?: boolean
}
export type ButtonIconProps = ButtonProps & BaseButtonIconProps

function ButtonIcon(props: ButtonIconProps, ref) {
  const {
    iconPosition = "left",
    iconSrc,
    iconSize,
    iconBackdrop,
    iconStyle,
    isLoading,
    children,
    ...buttonProps
  } = props

  return (
    <Button
      ref={ref}
      {...buttonProps}
    >
      {iconPosition === "left" && iconSrc
        ? <Icon
            src={iconSrc}
            size={iconSize}
            loading={isLoading}
            iconBackdrop={iconBackdrop}
            style={iconStyle}
          />
        : null
      }
      {children}
      {iconPosition === "right" && iconSrc
        ? <Icon
            src={iconSrc}
            size={iconSize}
            loading={isLoading}
            iconBackdrop={iconBackdrop}
            style={iconStyle}
          />
        : null
      }
    </Button>
  )
}

export default forwardRef<HTMLButtonElement, ButtonProps>(ButtonIcon)
