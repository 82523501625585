import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import { useCurrentUserLanguage }
  from "@/v2-console/currentUser/language/currentUser.language.utils"
import LANGUAGE_SELECTOR_TEXT, { type LangaugeSelectorTexts }
  from "@/v2-console-ui/languageSelector/languageSelector.text"
import LanguageSelector from "@/v2-console-ui/languageSelector/LanguageSelector"
import { useRootText } from "@/v2-console-shared/texts"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"

export type AppSidebarItemLanguageProps = Partial<SidebarItemProps>

function AppSidebarItemLanguage(props: AppSidebarItemLanguageProps) {
  const { onClick, ...sidebarItemProps } = props

  const { getText } = useRootText()

  const {
    activeLanguage,
    loading,
    error,
    languages,
    onLanguageChange
  } = useCurrentUserLanguage()

  return (
    <Sidebar.Item
      onClick={onClick}
      label={activeLanguage?.NativeName}
      iconSrc="language"
      isLoading={loading}
      aria-label={getText("UI_LanguageSelector_Root").Name}
      tooltip={getText("UI_LanguageSelector_Root").Name}
      contentSize="md"
      popoverContent={
        () => (
          <FeatureProvider<LangaugeSelectorTexts>
            code="UI_LanguageSelector"
            fallbackTexts={LANGUAGE_SELECTOR_TEXT}
            isLazyLoaded={false} // @note: Cannot lazy load this component without creating new loader component
          >
            <LanguageSelector
              activeLanguage={activeLanguage}
              languages={languages}
              onLanguageChange={onLanguageChange}
              loading={loading}
              error={error}
            />
          </FeatureProvider>
        )
      }
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemLanguage
