import { type ReactNode } from "react"
import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import { THEME_MODES }
  from "@/v2-ui/theme/theme.constants"
import { useThemeContext } from "@/v2-ui/theme/theme.context"
import { useRootText } from "@/v2-console-shared/texts"
import { trackAppThemeModeChangedByUser }
  from "@/v2-console/app/app.tracking"

export type ThemeCustomizerProps = {
  children?: ReactNode
}

export type AppSidebarItemThemeToggleProps = Partial<SidebarItemProps>

function AppSidebarItemThemeToggle(props: AppSidebarItemThemeToggleProps) {
  const { onClick, ...sidebarItemProps } = props
  const { theme, toggleDarkMode } = useThemeContext()

  const { getText } = useRootText()

  return (
    <Sidebar.Item
      onClick={() => {
        onClick?.()
        toggleDarkMode()
        const newMode = theme.mode === THEME_MODES.light
          ? THEME_MODES.dark
          : THEME_MODES.light
        trackAppThemeModeChangedByUser(newMode)
      }}
      iconSrc={theme.mode === THEME_MODES.light
        ? "sun"
        : "moon"
      }
      label={
        theme.mode === THEME_MODES.light
          ? getText("UI_Theme_Light_Root").Name
          : getText("UI_Theme_Dark_Root").Name
      }
      {...sidebarItemProps}

    />
  )
}

export default AppSidebarItemThemeToggle
