import StateDisplay from "@/v2-layout-ui/state"
import { STATE_LOADING_TYPES } from "@/v2-layout-ui/state/loading/state.loading.constants"

function FeatureLoadingFallback() {

  return (
    <StateDisplay
      stateType={STATE_LOADING_TYPES.LOADING}
    />
  )
}

export default FeatureLoadingFallback
