import type { TextsBase } from "@/v2-ui/text/text.context"
import type { TextQuery } from "@/v2-console/text/__types__/text.query"

type Res = TextQuery["getText"][0]

/**
 * Maps an array of response objects to a `TextsBase` object.
 *
 * If no response is provided, it returns `null`. Otherwise, it iterates
 * through the provided array of objects, extracting `Code`, `Name`, and
 * `Description` properties from each object, and assigning them to the
 * resulting `TextsBase` object where `Code` is the key.
 *
 * @param res - Optional array of response objects containing `Code`, `Name`, and `Description`.
 * @returns A `TextsBase` object or `null` if no response is provided.
 */
export function mapTexts(res?: Res[]) {
  const texts: TextsBase = {}
  if(!res) return null

  for(const { Code, Name, Description } of res) {
    texts[Code] = { Name, Description }
  }

  return texts
}
