import { useAppEvent } from "@/v2-console/app/context/AppContextProvider"

export const APP_SIDEBAR_MINIFY_EVENT = "app-sidebar-event"

export function useOnAppSidebarClose(cb: () => void) {
  useAppEvent(APP_SIDEBAR_MINIFY_EVENT, cb)
}

export function closeAppSidebar(events: EventTarget){
  const event = new CustomEvent(APP_SIDEBAR_MINIFY_EVENT)
  events.dispatchEvent(event)
}
