import { useMemo } from "react"
import type {
  SidebarMenuItemProps
} from "@/v2-ui/sidebar"
import Sidebar from "@/v2-ui/sidebar"
import Popover from "@/v2-ui/popover"
import { getActiveTenantFromCurrentUser, getUserFullName }
  from "@/v2-console-shared/user/user.utils"
import CurrentUserMenuAvatar
  from "@/v2-console-ui/currentUser/CurrentUserAvatar"
import { useAppContextQueryIsLoading }
  from "@/v2-console/app/context/app.context.query"
import { useAppContext }
  from "@/v2-console/app/context/AppContextProvider"
import { useTenantChange }
  from "@/v2-console/currentUser/tenant/currentUser.tenant.utils"
import { trackCurrentUserMenuShown }
  from "@/v2-console/currentUser/currentUser.tracking"
import CurrentUserMenuSuspender
  from "@/v2-console/currentUser/CurrentUserMenuSuspender"

export type AppSidebarCurrentUserItemProps = SidebarMenuItemProps & {
  loading?: boolean
}

function AppSidebarCurrentUserItem(props: AppSidebarCurrentUserItemProps) {
  const { loading, ...sidebarMenuItemProps } = props
  const isLoading = useAppContextQueryIsLoading()
  const { currentUser } = useAppContext()
  const {
    tenantChangeIsLoading
  } = useTenantChange()

  const userTenant = useMemo(() => {
    return getActiveTenantFromCurrentUser(currentUser)
  }, [ currentUser ])

  return (
    <Sidebar.Menu.Item
      {...sidebarMenuItemProps}
    >
      <Popover
        onOpenChange={(open) => {
          if(open) {
            trackCurrentUserMenuShown()
          }
        }}
      >
        <Popover.Trigger>
          <Sidebar.Menu.Button size="lg">
            <CurrentUserMenuAvatar
              isLoading={loading || isLoading || tenantChangeIsLoading}
              name={getUserFullName(currentUser)}
              subName={`${currentUser?.ActiveTenant?.Name} - ${userTenant?.UserTenantType?.Name}`}
            />
          </Sidebar.Menu.Button>
        </Popover.Trigger>
        <Popover.Content
          className="p-0"
          size="md"
          side="right"
          align="end"
          sideOffset={14}
        >
          <CurrentUserMenuSuspender />
        </Popover.Content>
      </Popover>
    </Sidebar.Menu.Item>
  )
}

export default AppSidebarCurrentUserItem
