import type { FallbackRender } from "@sentry/react"
import Button from "@/v2-ui/button"
import StateDisplay from "@/v2-layout-ui/state"
import { STATE_ERROR_TYPES }
  from "@/v2-layout-ui/state/error/state.error.constants"

const StateErrorFallbackRender: FallbackRender = ({ error, resetError }) => {
  const description = (
    <div className="flex flex-col justify-center items-center">
      <div>{error?.message}</div>
      {resetError
        ? <Button
            size="sm"
            className="mt-1"
            onClick={resetError}
          >
            Prøv igjen
          </Button>
        : null
      }
    </div>
  )

  return (
    <StateDisplay
      stateType={STATE_ERROR_TYPES.DEFAULT}
      title={error?.name}
      description={description}
    />
  )
}

export default StateErrorFallbackRender
