import type { ComponentProps } from "react"
import Tooltip from "@/v2-ui/tooltip"
import type { SidebarTrigger as SidebarTriggerBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import SidebarBase from "@/v2-ui/sidebar"
import { useSidebarContext }
  from "@/v2-layout-ui/sidebar"

export type SidebarToggleProps = ComponentProps<typeof SidebarTriggerBase> & {
  tooltip?: string
}

function SidebarToggle(props: SidebarToggleProps) {
  const {
    iconSrc = "sidebar",
    variant = "outline",
    size = "icon",
    tooltip = "Toggle Sidebar",
    onClick,
    ...sidebarTriggerProps
  } = props

  const { isMini, setIsMini } = useSidebarContext()
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <SidebarBase.Trigger
          aria-label={tooltip}
          iconSrc={iconSrc}
          variant={variant}
          size={size}
          onClick={(e) => {
            setIsMini(!isMini)
            onClick?.(e)
          }}
          {...sidebarTriggerProps}
        />
      </Tooltip.Trigger>
      <Tooltip.Content>
        {tooltip}
      </Tooltip.Content>
    </Tooltip>
  )
}

export default SidebarToggle
