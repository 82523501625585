import { forwardRef, type ComponentProps } from "react"
import { SidebarTrigger as SidebarTriggerBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarTriggerProps = ComponentProps<typeof SidebarTriggerBase>

function SidebarTrigger(props: SidebarTriggerProps, ref) {
  const { className, ...sidebarTriggerBaseProps } = props
  return (
    <SidebarTriggerBase
      className={cn("ui-sidebar-trigger", className)}
      ref={ref}
      {...sidebarTriggerBaseProps}
    />
  )
}

export default forwardRef(SidebarTrigger)
