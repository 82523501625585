import { useRef, useEffect } from "react"

function useEventListener<T = Event>(
  eventName: string,
  handler: (e: T) => void,
  element?,
  options?
) {
  let elem = element

  if(!elem && typeof window !== "undefined") {
    elem = window
  }

  const savedHandler = useRef(null)

  useEffect(() => {
    savedHandler.current = handler
  }, [
    handler
  ])

  useEffect(() => {
    const isSupported = elem && elem.addEventListener
    if(!isSupported) return
    const eventListener = (event) => {
      savedHandler.current(event)
    }
    elem.addEventListener(eventName, eventListener, options)
    return () => {
      elem.removeEventListener(eventName, eventListener, options)
    }
  }, [
    eventName,
    elem,
    options
  ])
}

export default useEventListener
