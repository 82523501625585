import type { ComponentProps } from "react"
import { useCallback, useRef } from "react"
import { useThemeContext } from "@/v2-ui/theme/theme.context"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import Separator from "@/v2-ui/separator"
import cn from "@/v2-ui/utils/utils.cn"
import Popover from "@/v2-ui/popover"
import Label from "@/v2-ui/label"
import ThemeCustomizerFontSizePicker from "@/v2-console-ui/themeCustomizer/ThemeCustomizerFontSizePicker"
import ThemeCustomizerThemePicker from "@/v2-console-ui/themeCustomizer/ThemeCustomizerThemePicker"
import Header from "@/v2-layout-ui/header"
import { CloseButton } from "@/v2-layout-ui/buttons"
// @note: probably will be used later..
// import ThemeCustomizerModePicker from "@/v2-console-ui/themeCustomizer/ThemeCustomizerModePicker"
import { useThemeCustomizerText }
  from "@/v2-console-ui/themeCustomizer/themeCustomizer.text"

type ThemeCustomizerProps = ComponentProps<"div"> & {
  onThemeChange?: (field: string, value: string | number) => void
}
function ThemeCustomizer(props: ThemeCustomizerProps) {
  const {
    className,
    onThemeChange,
    ...divProps
  } = props

  const { getText } = useThemeCustomizerText()

  const {
    theme,
    setTheme
  } = useThemeContext()
  const previousThemeConfig = useRef<ThemeConfig | null>(theme)

  const setThemeConfig = useCallback((themeConfig: ThemeConfig) => {
    // find the changed value
    if(previousThemeConfig.current) {
      const changedKeys = Object.keys(themeConfig).filter((key) => {
        return previousThemeConfig.current[key] !== themeConfig[key]
      })
      const changeKey = changedKeys.at(0)
      onThemeChange?.(changeKey, themeConfig[changeKey])
    }
    previousThemeConfig.current = themeConfig

    setTheme(themeConfig)
  }, [ onThemeChange, setTheme ])

  return (
    <>
      <Header
        title={getText("UI_Theme_Header").Name}
        renderCloseButton={(
          <Popover.Close
            className="ml-auto"
            asChild
          >
            <CloseButton />
          </Popover.Close>
        )}
      />
      <div
        className={cn("flex flex-col space-y-4", className)}
        {...divProps}
      >
        {/* @note: probably will be used later.. */}
        {/* <div className="space-y-1.5"> */}
        {/*   <Label>{getText("UI_Theme_ModeSelector").Description}</Label> */}
        {/*   <ThemeCustomizerModePicker */}
        {/*     themeConfig={theme} */}
        {/*     setThemeConfig={setThemeConfig} */}
        {/*   /> */}
        {/* </div> */}

        {/* <Separator className="-mx-4 !w-auto" /> */}

        <div className="space-y-1.5">
          <ThemeCustomizerThemePicker
            themeConfig={theme}
            setThemeConfig={setThemeConfig}
          />
        </div>

        <Separator className="-mx-4 !w-auto" />

        <div className="space-y-1.5">
          <Label>
            {getText("UI_Theme_FontSizeSelector").Name}
          </Label>
          <ThemeCustomizerFontSizePicker
            themeConfig={theme}
            setThemeConfig={setThemeConfig}
          />
        </div>
      </div>
    </>
  )
}

export default ThemeCustomizer
