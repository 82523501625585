import { forwardRef } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import { Button as ButtonBase, type ButtonProps as ButtonBaseProps } from "./base/ButtonBase"
import type { BaseButtonIconProps } from "./ButtonIcon"
import ButtonIcon from "./ButtonIcon"

export type ButtonProps = ButtonBaseProps & Partial<BaseButtonIconProps>

function Button(props: ButtonProps, ref) {
  const {
    iconPosition,
    iconSize,
    iconSrc,
    iconStyle,
    iconBackdrop,
    isLoading,
    // isSkeleton,
    variant,
    size,
    className,
    ...buttonBaseProps
  } = props

  // if(isSkeleton) {
  //   return (
  //     <Skeleton
  //       className={buttonCoreVariants({ variant, size, className })}
  //     />
  //   )
  // }

  if(iconSrc || isLoading) {
    return (
      <ButtonIcon
        ref={ref}
        variant={variant}
        size={size}
        iconPosition={iconPosition}
        iconSize={iconSize}
        iconSrc={iconSrc || "loading"} // @note: isLoading will nesseseraly not have iconSrc
        iconStyle={iconStyle}
        iconBackdrop={iconBackdrop}
        isLoading={isLoading}
        className={cn("ui-button__icon", className)}
        {...buttonBaseProps}
      />
    )
  }

  return (
    <ButtonBase
      ref={ref}
      variant={variant}
      size={size}
      className={cn("ui-button", className)}
      {...buttonBaseProps}
    />
  )
}

export default forwardRef<HTMLButtonElement, ButtonProps>(Button)
