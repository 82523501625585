import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import COUNTRY_SELECTOR_TEXT, { type CountrySelectorTexts }
  from "@/v2-console-ui/countrySelector/countrySelector.text"
import { useCurrentUserCountry }
  from "@/v2-console/currentUser/country/currentUser.country.utils"
import CountrySelector from "@/v2-console-ui/countrySelector/CountrySelector"
import { useRootText } from "@/v2-console-shared/texts"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"

export type AppSidebarItemCountryProps = Partial<SidebarItemProps>

function AppSidebarItemCountry(props: AppSidebarItemCountryProps) {
  const { onClick, ...sidebarItemProps } = props
  const { getText } = useRootText()

  const {
    activeCountry,
    loading,
    error,
    countries,
    onCountryChange
  } = useCurrentUserCountry()

  return (
    <Sidebar.Item
      onClick={onClick}
      iconSrc="globe"
      label={activeCountry?.Name}
      isLoading={loading}
      aria-label={getText("UI_CountrySelector_Root").Name}
      tooltip={getText("UI_CountrySelector_Root").Name}
      contentSize="md"
      popoverContent={() => (
        <FeatureProvider<CountrySelectorTexts>
          code="UI_CountrySelector"
          fallbackTexts={COUNTRY_SELECTOR_TEXT}
          isLazyLoaded={false} // @note: Cannot lazy load this component without creating new loader component
        >
          <CountrySelector
            activeCountry={activeCountry}
            countries={countries}
            onCountryChange={onCountryChange}
            loading={loading}
            error={error}
          />
        </FeatureProvider>
      )}
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemCountry
