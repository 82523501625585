import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Swatch from "@/v2-ui/swatch"
import { baseColors } from "@/v2-ui/theme/theme.baseColors"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import { useThemeCustomizerText }
  from "@/v2-console-ui/themeCustomizer/themeCustomizer.text"

const SELECTABLE_THEMES = [
  "zinc",
  "red",
  "rose",
  "orange",
  "green",
  "blue",
  "yellow",
  "violet"
]

type ThemeCustomizerThemePickerProps = ComponentProps<"div"> & {
  themeConfig: ThemeConfig,
  setThemeConfig: (themeConfig: ThemeConfig) => void
}
function ThemeCustomizerThemePicker(props: ThemeCustomizerThemePickerProps) {
  const {
    themeConfig,
    setThemeConfig,
    className,
    ...divProps
  } = props
  const { getText } = useThemeCustomizerText()

  return (
    <div
      className={cn("flex gap-1", className)}
      {...divProps}
    >
      {SELECTABLE_THEMES.map((themeName) => {
        const t = baseColors.find((t) => t.name === themeName)
        const isActive = themeConfig.theme === t.name && !themeConfig.overriddendCssVars

        return (
          <Swatch
            key={t.name}
            aria-label={getText("UI_Theme_ThemeSelector").Name}
            isActive={isActive}
            color={`hsl(${t?.activeColor[themeConfig.mode === "dark" ? "dark" : "light"]})`}
            onClick={() => {
              const {
                overriddendCssVars: _,
                ...themeConfigWithoutOverrides
              } = themeConfig
              setThemeConfig({
                ...themeConfigWithoutOverrides,
                theme: t.name
              })
            }}
          />
        )
      })}
    </div>
  )
}

export default ThemeCustomizerThemePicker
