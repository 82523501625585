import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

import { useIsMobile } from "@/v2-ui/utils/utils.mobile"
import cn from "@/v2-ui/utils/utils.cn"
import Button from "@/v2-ui/button"
import Input from "@/v2-ui/input"
import Separator from "@/v2-ui/separator"
import Sheet from "@/v2-ui/sheet"
import Skeleton from "@/v2-ui/skeleton"
import Tooltip from "@/v2-ui/tooltip"

// const SIDEBAR_COOKIE_NAME = "sidebar:state"
// const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7
const SIDEBAR_WIDTH = "12rem"
const SIDEBAR_WIDTH_MOBILE = "12rem"
const SIDEBAR_WIDTH_ICON = "3.25rem"
// const SIDEBAR_KEYBOARD_SHORTCUT = "b"

type SidebarContext = {
  state: "expanded" | "collapsed"
  open: boolean
  setOpen: (open: boolean) => void
  openMobile: boolean
  setOpenMobile: (open: boolean) => void
  isMobile: boolean
  toggleSidebar: () => void
}

const SidebarContext = React.createContext<SidebarContext | null>(null)

function useSidebar() {
  const context = React.useContext(SidebarContext)
  if(!context) {
    throw new Error("useSidebar must be used within a SidebarProvider.")
  }

  return context
}

const SidebarProvider = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
  }
>(
  (
    {
      defaultOpen = true,
      open: openProp,
      onOpenChange: setOpenProp,
      style,
      children,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile()
    const [ openMobile, setOpenMobile ] = React.useState(false)

    // This is the internal state of the sidebar.
    // We use openProp and setOpenProp for control from outside the component.
    const [ _open, _setOpen ] = React.useState(defaultOpen)
    const open = openProp ?? _open
    const setOpen = React.useCallback(
      (value: boolean | ((value: boolean) => boolean)) => {
        if(setOpenProp) {
          return setOpenProp?.(
            typeof value === "function" ? value(open) : value
          )
        }

        _setOpen(value)

        // This sets the cookie to keep the sidebar state.
        // eslint-disable-next-line unicorn/no-document-cookie
        // document.cookie = `${SIDEBAR_COOKIE_NAME}=${open}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`
      },
      [ setOpenProp, open ]
    )

    // Helper to toggle the sidebar.
    const toggleSidebar = React.useCallback(() => {
      return isMobile
        ? setOpenMobile((open) => !open)
        : setOpen((open) => !open)
    }, [ isMobile, setOpen, setOpenMobile ])

    // Adds a keyboard shortcut to toggle the sidebar.
    // React.useEffect(() => {
    //   const handleKeyDown = (event: KeyboardEvent) => {
    //     console.log("fdsfdsfsfsdfsdfsdf", )
    //     if(
    //       event.key === SIDEBAR_KEYBOARD_SHORTCUT
    //       && (event.metaKey || event.ctrlKey)
    //     ) {
    //       event.preventDefault()
    //       toggleSidebar()
    //     }
    //   }

    //   window.addEventListener("keydown", handleKeyDown)
    //   return () => window.removeEventListener("keydown", handleKeyDown)
    // }, [ toggleSidebar ])

    // We add a state so that we can do data-state="expanded" or "collapsed".
    // This makes it easier to style the sidebar with Tailwind classes.
    const state = open ? "expanded" : "collapsed"

    const contextValue = React.useMemo<SidebarContext>(
      () => ({
        state,
        open,
        setOpen,
        isMobile,
        openMobile,
        setOpenMobile,
        toggleSidebar
      }),
      [ state, open, setOpen, isMobile, openMobile, setOpenMobile, toggleSidebar ]
    )

    return (
      <SidebarContext.Provider value={contextValue}>
        <Tooltip.Provider delayDuration={0}>
          <div
            style={
              {
                "--sidebar-width": SIDEBAR_WIDTH,
                "--sidebar-width-icon": SIDEBAR_WIDTH_ICON,
                ...style
              } as React.CSSProperties
            }
            ref={ref}
            {...props}
          >
            {children}
          </div>
        </Tooltip.Provider>
      </SidebarContext.Provider>
    )
  }
)
SidebarProvider.displayName = "SidebarProvider"

const Sidebar = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    side?: "left" | "right"
    variant?: "sidebar" | "floating" | "inset"
    collapsible?: "offcanvas" | "icon" | "none"
  }
>(
  (
    {
      side,
      variant,
      collapsible,
      children,
      ...props
    },
    ref
  ) => {
    const { isMobile, state, openMobile, setOpenMobile } = useSidebar()

    if(collapsible === "none") {
      return (
        <div
          ref={ref}
          {...props}
        >
          {children}
        </div>
      )
    }

    if(isMobile) {
      return (
        <Sheet
          open={openMobile} onOpenChange={setOpenMobile}
          {...props}
        >
          <Sheet.Content
            data-sidebar="sidebar"
            data-mobile="true"
            className="!p-0 w-[--sidebar-width] bg-sidebar text-sidebar-foreground [&>button]:hidden"
            style={
              {
                "--sidebar-width": SIDEBAR_WIDTH_MOBILE
              } as React.CSSProperties
            }
            side={side}
          >
            {/* This is needed for not trowind an accessibility error in console*/}
            <VisuallyHidden.Root>
              <Sheet.Title>
                Sidebar mobile
              </Sheet.Title>
            </VisuallyHidden.Root>
            <VisuallyHidden.Root>
              <Sheet.Description>
                Sidebar mobile
              </Sheet.Description>
            </VisuallyHidden.Root>
            {/**/}

            <div className="flex h-full w-full flex-col">{children}</div>
          </Sheet.Content>
        </Sheet>
      )
    }

    return (
      <div
        ref={ref}
        className="ui-sidebar-root group peer hidden md:block text-sidebar-foreground"
        data-state={state}
        data-collapsible={state === "collapsed" ? collapsible : ""}
        data-variant={variant}
        data-side={side}
      >
        {/* This is what handles the sidebar gap on desktop */}
        <div
          className={cn(
            "duration-200 relative h-svh w-[--sidebar-width] bg-transparent transition-[width] ease-linear",
            "group-data-[collapsible=offcanvas]:w-0",
            "group-data-[side=right]:rotate-180",
            variant === "floating" || variant === "inset"
              ? "group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]"
              : "group-data-[collapsible=icon]:w-[--sidebar-width-icon]"
          )}
        />
        <div
          {...props}
        >
          <div
            data-sidebar="sidebar"
            className="flex h-full w-full flex-col bg-sidebar group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-sidebar-border group-data-[variant=floating]:shadow"
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
)
Sidebar.displayName = "Sidebar"

const SidebarTrigger = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentProps<typeof Button>
>(({ onClick, ...props }, ref) => {
  const { toggleSidebar } = useSidebar()

  return (
    <Button
      ref={ref}
      data-sidebar="trigger"
      variant="ghost"
      size="icon"
      onClick={(event) => {
        onClick?.(event)
        toggleSidebar()
      }}
      {...props}
    >
      <span className="sr-only">Toggle Sidebar</span>
    </Button>
  )
})
SidebarTrigger.displayName = "SidebarTrigger"

const SidebarRail = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    onDragStart?: () => void
    onDragged?: (direction: string) => void
    onDragEnd?: (direction: string) => void
  }
>(({ onClick, onDragStart, onDragged, onDragEnd, ...props }, ref) => {
  // @note:: removed this because it became messy
  // const { toggleSidebar } = useSidebar()

  const isDragging = React.useRef(false)
  const startPosition = React.useRef({ x: 0, y: 0 })

  const handleMouseDown = (event: React.MouseEvent) => {
    isDragging.current = true
    startPosition.current = { x: event.clientX, y: event.clientY }
    onDragStart?.()
    document.addEventListener("mouseup", handleMouseUp)

  }

  const handleMouseMove = (event: React.MouseEvent) => {
    if(isDragging.current) {
      const direction = getDragDirection(startPosition.current, { x: event.clientX, y: event.clientY })
      onDragged?.(direction)
    }
  }

  const handleMouseUp = (event: MouseEvent) => {
    if(isDragging.current) {
      const endPosition = { x: event.clientX, y: event.clientY }
      const direction = getDragDirection(startPosition.current, endPosition)
      isDragging.current = false
      startPosition.current = { x: 0, y: 0 }
      onDragEnd?.(direction)
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getDragDirection = (start: { x: number, y: number }, end: { x: number, y: number }) => {
    const deltaX = end.x - start.x
    return deltaX > 0 ? "right" : "left"
  }

  return (
    <button
      ref={ref}
      data-sidebar="rail"
      aria-label="Toggle Sidebar"
      tabIndex={-1}
      onClick={(event) => {
        event.preventDefault()
        onClick?.(event)
        // @note:: removed this because it became messy
        // toggleSidebar()
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      title="Toggle Sidebar"
      {...props}
    />
  )
})
SidebarRail.displayName = "SidebarRail"

const SidebarInset = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"main">
>(({ ...props }, ref) => {
  return (
    <main
      ref={ref}
      {...props}
    />
  )
})
SidebarInset.displayName = "SidebarInset"

const SidebarInput = React.forwardRef<
  React.ElementRef<typeof Input>,
  React.ComponentProps<typeof Input>
>(({ ...props }, ref) => {
  return (
    <Input
      ref={ref}
      data-sidebar="input"
      {...props}
    />
  )
})
SidebarInput.displayName = "SidebarInput"

const SidebarHeader = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="header"
      {...props}
    />
  )
})
SidebarHeader.displayName = "SidebarHeader"

const SidebarFooter = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="footer"
      {...props}
    />
  )
})
SidebarFooter.displayName = "SidebarFooter"

const SidebarSeparator = React.forwardRef<
  React.ElementRef<typeof Separator>,
  React.ComponentProps<typeof Separator>
>(({ ...props }, ref) => {
  return (
    <Separator
      ref={ref}
      data-sidebar="separator"
      {...props}
    />
  )
})
SidebarSeparator.displayName = "SidebarSeparator"

const SidebarContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="content"
      {...props}
    />
  )
})
SidebarContent.displayName = "SidebarContent"

const SidebarGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="group"
      {...props}
    />
  )
})
SidebarGroup.displayName = "SidebarGroup"

const SidebarGroupLabel = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & { asChild?: boolean }
>(({ asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "div"

  return (
    <Comp
      ref={ref}
      data-sidebar="group-label"
      {...props}
    />
  )
})
SidebarGroupLabel.displayName = "SidebarGroupLabel"

const SidebarGroupAction = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & { asChild?: boolean }
>(({ asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"

  return (
    <Comp
      ref={ref}
      data-sidebar="group-action"
      {...props}
    />
  )
})
SidebarGroupAction.displayName = "SidebarGroupAction"

const SidebarGroupContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => (
  <div
    ref={ref}
    data-sidebar="group-content"
    {...props}
  />
))
SidebarGroupContent.displayName = "SidebarGroupContent"

const SidebarMenu = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ ...props }, ref) => (
  <ul
    ref={ref}
    data-sidebar="menu"
    {...props}
  />
))
SidebarMenu.displayName = "SidebarMenu"

const SidebarMenuItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ ...props }, ref) => (
  <li
    ref={ref}
    data-sidebar="menu-item"
    {...props}
  />
))
SidebarMenuItem.displayName = "SidebarMenuItem"

const SidebarMenuButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    asChild?: boolean
    isActive?: boolean
    tooltip?: React.ReactNode
  }
>(
  (
    {
      asChild = false,
      isActive = false,
      tooltip,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    const { isMobile, state } = useSidebar()

    const button = (
      <Comp
        ref={ref}
        data-sidebar="menu-button"
        data-active={isActive}
        {...props}
      />
    )

    if(!tooltip) {
      return button
    }

    // if(typeof tooltip === "string") {
    //   tooltip = {
    //     children: tooltip
    //   }
    // }

    return (
      <Tooltip>
        <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
        <Tooltip.Content
          side="right"
          align="center"
          hidden={state !== "collapsed" || isMobile}
        >
          {tooltip}
        </Tooltip.Content>
      </Tooltip>
    )
  }
)
SidebarMenuButton.displayName = "SidebarMenuButton"

const SidebarMenuAction = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    asChild?: boolean
    showOnHover?: boolean
  }
>(({ asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"

  return (
    <Comp
      ref={ref}
      data-sidebar="menu-action"
      {...props}
    />
  )
})
SidebarMenuAction.displayName = "SidebarMenuAction"

const SidebarMenuBadge = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ ...props }, ref) => (
  <div
    ref={ref}
    data-sidebar="menu-badge"
    {...props}
  />
))
SidebarMenuBadge.displayName = "SidebarMenuBadge"

const SidebarMenuSkeleton = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    showIcon?: boolean
    showLabel?: boolean
    randomWidth?: boolean
  }
>(({
  showIcon = true,
  showLabel = true,
  randomWidth = true,
  ...props
}, ref) => {
  // Random width between 50 to 90%.
  const width = React.useMemo(() => {
    return randomWidth ? `${Math.floor(Math.random() * 40) + 50}%` : "100%"
  }, [ randomWidth ])

  return (
    <div
      ref={ref}
      data-sidebar="menu-skeleton"
      {...props}
    >
      {showIcon && (
        <Skeleton
          className="size-4 rounded-md"
          data-sidebar="menu-skeleton-icon"
        />
      )}
      {showLabel ? (
        <Skeleton
          className="h-4 flex-1 max-w-[--skeleton-width]"
          data-sidebar="menu-skeleton-text"
          style={
            {
              "--skeleton-width": width
            } as React.CSSProperties
          }
        />
      ) : null}
    </div>
  )
})
SidebarMenuSkeleton.displayName = "SidebarMenuSkeleton"

const SidebarMenuSub = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ ...props }, ref) => (
  <ul
    ref={ref}
    data-sidebar="menu-sub"
    {...props}
  />
))
SidebarMenuSub.displayName = "SidebarMenuSub"

const SidebarMenuSubItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ ...props }, ref) => <li ref={ref} {...props} />)
SidebarMenuSubItem.displayName = "SidebarMenuSubItem"

const SidebarMenuSubButton = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<"a"> & {
    asChild?: boolean
    size?: "sm" | "xs"
    isActive?: boolean
  }
>(({ asChild = false, isActive, ...props }, ref) => {
  const Comp = asChild ? Slot : "a"

  return (
    <Comp
      ref={ref}
      data-sidebar="menu-sub-button"
      data-active={isActive}
      {...props}
    />
  )
})
SidebarMenuSubButton.displayName = "SidebarMenuSubButton"

export {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupAction,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInput,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar
}
