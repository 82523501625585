import { useText } from "@/v2-ui/text/text.context"
import type { RootTexts } from "@/v2-console-shared/texts"

const COUNTRY_SELECTOR_TEXT = {
  "UI_CountrySelector_Header": {
    "Name": "Country"
  },
  "UI_CountrySelector_SearchPlaceholder": {
    "Name": "Search for a country"
  },
  "UI_CountrySelector_SearchEmpty": {
    "Name": "No countries found..."
  }
} as const

export type CountrySelectorTexts = typeof COUNTRY_SELECTOR_TEXT & RootTexts
export const useCountrySelectorText = useText<CountrySelectorTexts>
export default COUNTRY_SELECTOR_TEXT
