import type { ButtonProps } from "@/v2-ui/button"
import cn from "@/v2-ui/utils/utils.cn"
import Button from "@/v2-ui/button"
import Icon from "@/v2-ui/icon"
import { colord } from "colord"

export type SwatchProps = Omit<ButtonProps, "variant"> & {
  color: string,
  isActive?: boolean,
}

function Swatch(props: SwatchProps) {
  const {
    color,
    isActive,
    size = "icon",
    className,
    ...buttonProps
  } = props

  return (
    <Button
      size={size}
      variant="ghost"
      className={cn("flex justify-center items-center", className)}
      {...buttonProps}
    >
      <span
        className="flex h-5 w-5 justify-center items-center rounded-full border"
        style={{ backgroundColor: color }}
      >
        {isActive
          ? <Icon
              src="check"
              size={10}
              className={cn("", {
                "text-white": colord(color).isDark(),
                "text-black": colord(color).isLight()
              })}
            />
          : null
        }
      </span>
    </Button>
  )
}

export default Swatch
